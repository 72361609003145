import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/forge/docs.wild.plus/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from "docz";
import { Code } from "gatsby-theme-docz/src/components/Code";
import { Grid } from "./Grid";
import { PropsTable } from "./Grid.props";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "grid"
    }}>{`Grid`}</h1>
    <p>{`Grid component based on the CSS Grid Layout spec.`}</p>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <Code className="bash" mdxType="Code">{`yarn add @madebywild/styled-grid`}</Code>
    <Code className="js" mdxType="Code">{`import { Grid } from '@madebywild/styled-grid'`}</Code>
    <h2 {...{
      "id": "props-table"
    }}>{`Props table`}</h2>
    <Props of={PropsTable} mdxType="Props" />
    <h3 {...{
      "id": "rows-columns"
    }}>{`Rows, Columns`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Grid columns={3} /> // grid-template-columns: repeat(3, 1fr)
<Grid columns={[3, 1]} /> // grid-template-columns: repeat(3fr, 1fr)
<Grid columns={[1, 3, '10%']} /> // grid-template-columns: repeat(1fr, 3fr, '10%')
`}</code></pre>
    <h3 {...{
      "id": "gap"
    }}>{`Gap`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Grid gap={3} /> // gap: 3px
<Grid gap={[3, 10]} /> // gap: 3px 10px
`}</code></pre>
    <h3 {...{
      "id": "alignment"
    }}>{`Alignment`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Grid alignItems={"start"} /> // align-items: flex-start
<Grid justifyContent={"end"} /> // justify-content: flex-end
<Grid justifyContent={"center"} /> // justify-content: center
`}</code></pre>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <Playground __position={3} __code={'<Grid\n  columns={[1, 3]}\n  rows={[1, 5, 1]}\n  gap={10}\n  areas={[\'header header\', \'sidebar content\', \'footer footer\']}\n>\n  <Grid.Item area=\"header\" style={{ background: \'darkgray\', padding: 20 }} />\n  <Grid.Item area=\"sidebar\" style={{ background: \'darkgray\', padding: 20 }} />\n  <Grid.Item area=\"content\" style={{ background: \'darkgray\', padding: 20 }} />\n  <Grid.Item area=\"footer\" style={{ background: \'darkgray\', padding: 20 }} />\n</Grid>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Code,
      Grid,
      PropsTable,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Grid columns={[1, 3]} rows={[1, 5, 1]} gap={10} areas={["header header", "sidebar content", "footer footer"]} mdxType="Grid">
    <Grid.Item area="header" style={{
          background: "darkgray",
          padding: 20
        }} />
    <Grid.Item area="sidebar" style={{
          background: "darkgray",
          padding: 20
        }} />
    <Grid.Item area="content" style={{
          background: "darkgray",
          padding: 20
        }} />
    <Grid.Item area="footer" style={{
          background: "darkgray",
          padding: 20
        }} />
  </Grid>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      